import { CheckSession, CheckSessionInput, Login, LoginInput, Signup, SignupInput } from '../../../../../packages/shared/interfaces/shared.interface';
import { request } from './request';

// TODO: Type output here and in backend(from shared)
export class UsersApi {
  public static userSignup(body: { name: string; email: string; password: string; passwordConfirm: string }) {
    return request<SignupInput, Signup>('/user/signup', { method: 'POST', body });
  }

  public static userLogin(body: { email: string; password: string }) {
    return request<LoginInput, Login>('/user/login', { method: 'POST', body });
  }

  public static checkSession() {
    return request<CheckSessionInput, CheckSession>('/user/check-session', { method: 'GET' });
  }
}
