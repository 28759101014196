import { MiscUtils } from '../../utils/misc.utils';
import { dispatch } from '../redux/store';
import { setTrigger } from '../redux/reducers/session.reducer';
import { BaseResponse, ErrorResponse, SuccessResponse } from '../../../../../packages/shared/interfaces/api.interface';

/**
 * Main request function - using same types as in the API
 * @obs Query params can be added with "queryParams", while Url params can be interpolated in the url string directly
 * @param token Bearer token. Defaults to local storage "token" when not defined.
 * @param noCredentials set to true to omit cookies for authentication
 * @param errorMessage custom error message. Shows toast on error. Defaults to response error message.
 */

interface ApiInput {
  queryParams?: string | Record<string, string> | URLSearchParams | string[][] | undefined;
  body?: Record<string, any>;
}

type RequestOptions<I extends ApiInput = ApiInput> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: I['body'];
  noCredentials?: boolean;
  headers?: Record<string, string>;
  queryParams?: I['queryParams'];
  errorMessage?: string | true;
  showToast?: boolean;
};

// TODO: Make a catch when request returns non-authenticated to redirect to login page
/**
 *
 * @param url
 * @param options
 * @returns value when success. null when error.
 */
export async function request<I extends ApiInput, T>(url: string, options: RequestOptions<I>): Promise<SuccessResponse<T> | ErrorResponse> {
  try {
    const headers: HeadersInit = { 'Content-Type': 'application/json', ...options.headers };

    let queryParams = '';
    if (options.queryParams) {
      const searchParams = options.queryParams instanceof URLSearchParams ? options.queryParams : new URLSearchParams(options.queryParams);
      queryParams = `?${searchParams.toString()}`;
    }

    const requestBody = options.body ? JSON.stringify(options.body) : null;

    const response = await fetch(`${MiscUtils.env('REACT_APP_API_URL')}${url}${queryParams}`, {
      method: options.method,
      headers,
      body: requestBody,
      credentials: options.noCredentials ? 'omit' : 'include'
    });

    const jsonResponse = await response.json();

    if (!response.ok) throw jsonResponse;

    return jsonResponse;
  } catch (error) {
    // TODO: Allow option in request to have custom catch callback
    console.info({
      request: `${url}`,
      error
    }); // TODO: in dev mode

    if (options.errorMessage) {
      const isCustomErrorMessage = typeof options.errorMessage !== 'boolean';
      displayErrorToast(isCustomErrorMessage ? (options.errorMessage as string) : 'Something went wrong. Please try again.');
    }
    return error as ErrorResponse;
  }
}

/** Wrap around response to check if it is error */
export function isErrorResponse(response: BaseResponse): response is ErrorResponse {
  return response.status === 'fail' || response.status === 'error';
}

function displayErrorToast(message: string) {
  dispatch(setTrigger({ name: 'trigger-notification', message }));
}
