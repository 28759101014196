import { Button, ButtonProps } from '../ui/button';

import StyleUtils from '../../utils/style.utils';
import style from './action-button.module.scss';
const s = StyleUtils.styleMixer(style);

/**
 * Mobile) Fixed component at the bottom with main and secondary buttons
 */
export default function ActionButton(
  props: {
    mainBtnLabel: string;
    mainBtn: () => void;
    secondBtnLabel: string;
    secondBtnLabelBold: string;
    secondBtn: () => void;
  } & ButtonProps
) {
  const { mainBtnLabel, mainBtn, secondBtnLabel, secondBtnLabelBold, secondBtn, ...rest } = props;

  return (
    <div className={s('container')}>
      <Button
        className={s('btn-main')}
        onClick={(e) => {
          mainBtn();
        }}
        {...rest}
      >
        {mainBtnLabel}
      </Button>
      <a
        onClick={(e) => {
          e.preventDefault();
          secondBtn();
        }}
      >
        {secondBtnLabel} <strong>{secondBtnLabelBold}</strong>
      </a>
    </div>
  );
}
