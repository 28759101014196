import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';

import { UsersApi } from './data/api/users.api';
import { isErrorResponse } from './data/api/request';

import { AppState, dispatch } from './data/redux/store';
import { login, logout } from './data/redux/reducers/user.reducer';

import { withInitialization } from './hoc/with-initialization.hoc';

import JournalPage from './pages/journal/journal.page';
import LoginPage from './pages/login/login.page';
import TestPage from './pages/test/test.page';

import SysNotification from './containers/sys-notification.container';
import { Toaster } from './components/ui/toaster';
import { Button } from './components/ui/button';

import style from './app.module.scss';
import StyleUtils from './utils/style.utils';
const s = StyleUtils.styleMixer(style);

export default withInitialization(AppRaw, initJournalPage);

async function initJournalPage() {
  await UsersApi.checkSession().then((res) => {
    if (isErrorResponse(res)) return;
    dispatch(login({ user: res.data }));
  });
}

function AppRaw() {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  const userData = useSelector((state: AppState) => state.user.userData);

  return (
    <div className={s('container')}>
      <BrowserRouter>
        {isLoggedIn && (
          <nav className={s('nav')}>
            <div className={s('user-title')}>{'Hi ' + userData.user?.name || ''}</div>
            <Button onClick={() => dispatch(logout())}>
              <Link to='/'>Logout</Link>
            </Button>
          </nav>
        )}

        <div className={s('pages')}>
          <Routes>
            <Route path='/test' element={<TestPage />} />
            <Route
              path='/journal'
              element={
                <ProtectedRoute>
                  <JournalPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/about'
              element={
                <ProtectedRoute>
                  <JournalPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/contact'
              element={
                <ProtectedRoute>
                  <JournalPage />
                </ProtectedRoute>
              }
            />
            <Route path='/' element={<LoginPage />} />
          </Routes>
        </div>
      </BrowserRouter>
      <Toaster />
      <SysNotification />
    </div>
  );
}

function ProtectedRoute({ children }: any) {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
  return isLoggedIn ? children : <Navigate to='/' />;
}
