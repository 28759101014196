import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../../data/redux/store';

import BackgroundTheme from '../../components/background-theme/background-theme.component';
import WelcomeScreen from './screens/welcome.container';
import SignupScreen from './screens/signup.container';
import LoginScreen from './screens/login.container';

import { Chevron } from '../../icons/Chevron';

import StyleUtils from '../../utils/style.utils';
import style from './login.module.scss';
const s = StyleUtils.styleMixer(style);

export default function LoginPage() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn);
  const [isScreen, setIsScreen] = useState<'welcome' | 'signup' | 'login'>('welcome');

  useEffect(() => {
    if (isLoggedIn) navigate('/journal');
    else {
      const hasVisited = localStorage.getItem('hasVisited');
      if (hasVisited) setIsScreen('login');
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className={s('container')}>
      <BackgroundTheme>
        {/* // TODO: Make into re usable component (in case it is used in other app pages) - a small no-op change - a second change :) */}
        <div className={s('header')} style={{ display: isScreen !== 'welcome' ? 'block' : 'none' }}>
          <div className={s('chevron-previous')}>
            <Chevron
              type='chevronVariant'
              onClick={(e) => {
                e.preventDefault();
                setIsScreen('welcome');
              }}
            />
          </div>
          <h2>Active Sloth</h2>
        </div>

        <WelcomeScreen isShowing={isScreen === 'welcome'} goToLoginScreen={() => setIsScreen('login')} goToSignup={() => setIsScreen('signup')} />
        <SignupScreen isShowing={isScreen === 'signup'} goToLoginScreen={() => setIsScreen('login')} />
        <LoginScreen isShowing={isScreen === 'login'} goToSignup={() => setIsScreen('signup')} />
      </BackgroundTheme>
    </div>
  );
}
