import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DisplayDay } from '../../../../containers.interface';

import { JournalUtils } from '../../../journal.utlils';
import withMemo from '../../../../../hoc/with-memo.hoc';
import { useStateRefNR } from '../../../../../hooks/use-state-ref-no-render.hook';
import withAsyncProps from '../../../../../hoc/with-async-props.hoc';

import DayCell from '../day-cell/day-cell.component';

import style from './day-grid.module.scss';
import StyleUtils from '../../../../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

/**
 * Memoized and AsyncProps DayGrid - displays days in a grid with adjustable heights in two columns.
 * @see DayGridRaw for the underlying component.
 */
const DayGrid = withAsyncProps(withMemo(DayGridRaw), asyncProcessProps);
export default DayGrid;

// TODO: not needed... for now
async function asyncProcessProps(props: any) {
  return props;
}

/**
 * DayGridRaw - This component renders a grid of days with uneven heights.
 * @see DayGrid for the HOC-wrapped version.
 */
function DayGridRaw(props: { filledDays: DisplayDay[] }) {
  const { filledDays } = props;

  const [heights, setHeights] = useStateRefNR<{ [key: string]: number }>({});
  const [measured, setMeasured] = useState(false);

  const columns: DisplayDay[][] = useMemo(() => {
    if (measured) {
      const columns = JournalUtils.measureColumns(heights(), filledDays);
      setHeights({});
      return columns;
    } else {
      return [[]];
    }
  }, [filledDays, measured]);

  useLayoutEffect(() => {
    if (measured) {
      // Will cause all cells to adjust to new screen dimensions (makes container go through the first pass again)
      setMeasured(false);
    }
  }, [filledDays]);

  const containerRef = useRef(null);

  return (
    <div className={s('container')} ref={containerRef}>
      <div className={s('grid-container')}>
        {measured ? (
          // Second pass: Render with proper columns
          <>
            <div className={s('grid-column')}>
              {columns[0].map((day) => (
                <DayCell key={day.date} day={day} />
              ))}
            </div>
            <div className={s('grid-column')}>
              {columns[1].map((day) => (
                <DayCell key={day.date} day={day} />
              ))}
            </div>
          </>
        ) : (
          // First pass: Render all cells in a single column for height measurement - No user interaction here
          <div className={s('grid-one-column')}>
            {filledDays.map((day) => {
              return (
                <DayCell
                  key={`${day.date}-${String(Symbol())}`}
                  day={day}
                  updateHeight={(height) => {
                    handleHeightPerCell(day.date, height);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );

  /** For each cell in a column, height.  */
  function handleHeightPerCell(dateString: string, height: number) {
    setHeights({ ...heights(), [dateString]: height });

    if (Object.keys(heights()).length === filledDays.length) {
      setTimeout(() => {
        setMeasured(true);
      }, 100);
    }
  }
}
