import type { ComponentPropsWithoutRef } from 'react'

export const Chevron = ({ ...props }: ComponentPropsWithoutRef<'svg'>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='9.214' height='5.357' viewBox='0 0 9.214 5.357' {...props}>
    <title>Chevron</title>
    <path
      d='M55.093,96l-3.546,3.546L48,96'
      transform='translate(-46.939 -94.939)'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
)
