import { ReactNode } from 'react'

import StyleUtils from '../../utils/style.utils'
import style from './background-theme.module.scss'
const s = StyleUtils.styleMixer(style)

/**
 * Occupies parent with thematic background
 */
const BackgroundTheme = (props: { children?: ReactNode }) => {
  return <div className={s('background')}>{props.children}</div>
}

export default BackgroundTheme
