import { useEffect } from 'react';
import { useEffectIf } from './use-effect-if.hook';
import { useStateRef } from './use-state-ref.hook';

/**
 * Gets the ref to a DOM element and, on change, returns "width" and "height" of said element
 * @obs triggered on ref change and triggers rerender
 * @param ref of element to be watching
 * @param forceRefresh will force a recalculation when this boolean only changes to "true"
 * @returns width, height, in px, of ref
 */
export function useResize(ref: any | null, forceRefresh?: boolean) {
  const [[, getWith], setWidth] = useStateRef(0);
  const [[, getHeight], setHeight] = useStateRef(0);

  useEffectIf(() => {
    handleResize();
  }, [forceRefresh]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return { width: getWith(), height: getHeight() };

  function handleResize() {
    if (!ref?.current) return;

    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }
}
