import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { ApiService } from '../../../data/services/api.service';

import ActionButton from '../../../components/action-button/action-button.component';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';

import StyleUtils from '../../../utils/style.utils';
import style from './screens.module.scss';

const s = StyleUtils.styleMixer(style);

const formSchema = z.object({
  email: z.string().min(2, {
    message: 'Email must be at least 2 characters.'
  }),
  name: z.string(),
  password: z.string(),
  passwordConfirm: z.string()
});

export default function SignupScreen(props: { isShowing: boolean; goToLoginScreen: () => void }) {
  const formSignup = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      name: '',
      password: '',
      passwordConfirm: ''
    }
  });

  const formRef = useRef<HTMLFormElement>(null);

  async function onSubmitSignup(values: z.infer<typeof formSchema>) {
    ApiService.authenticate('signup', { ...values });
  }

  return (
    <div className={s('signup-screen', props.isShowing ? 'anim-slide-in' : 'anim-slide-out')}>
      <div className={s('form-card')}>
        <h2>Sign Up</h2>
        <Form {...formSignup}>
          <form ref={formRef} onSubmit={formSignup.handleSubmit(onSubmitSignup)} className='space-y-8'>
            <FormField
              control={formSignup.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formSignup.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formSignup.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type='password' placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formSignup.control}
              name='passwordConfirm'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input type='password' placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <button type='submit' style={{ display: 'none' }}></button> {/* Hidden submit button (for form actions/listeners) */}
          </form>
        </Form>
      </div>
      <ActionButton
        type='button'
        mainBtnLabel='Sign Up'
        mainBtn={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
        secondBtnLabel='Already have an account?'
        secondBtnLabelBold='Login'
        secondBtn={() => {
          props.goToLoginScreen();
        }}
      />
    </div>
  );
}
