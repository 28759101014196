import { ReactElement } from 'react';

import { Button } from '../ui/button';

import style from './button-icon.module.scss';
import StyleUtils from '../../utils/style.utils';

const s = StyleUtils.styleMixer(style);

export interface ButtonIconProps {
  children: ReactElement | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * Button with Icon
 * @usage adapts to parent's size. wrapp it with fitted div. Receives children icon.
 */
export function ButtonIcon(props: ButtonIconProps) {
  const { children, onClick } = props;

  return (
    <div className={s('container')}>
      <Button
        variant='outline'
        size='icon'
        onClick={(e) => {
          onClick && onClick(e);
        }}
      >
        {children}
      </Button>
    </div>
  );
}
