import { ApiService } from '../../data/services/api.service';

import { AppState, dispatch } from '../../data/redux/store';
import { useSelector } from 'react-redux';
import { setToggleSideContent } from '../../data/redux/reducers/journal.reducer';

import { withInitialization } from '../../hoc/with-initialization.hoc';
import DayForm from './sections/day-form/day-form.container';
import DayCalendar from './sections/day-calendar/day-calendar.container';
import { ButtonIcon } from '../../components/button-icon/button-icon.component';

import { ChevronRightIcon } from '@radix-ui/react-icons';
import StyleUtils from '../../utils/style.utils';
import style from './journal.module.scss';
import { ScrollArea } from '../../components/ui/scroll-area';
const s = StyleUtils.styleMixer(style);

export default withInitialization(JournalPageRaw, initJournalPage);

async function initJournalPage() {
  await ApiService.fetchCurrentMonth();
}

function JournalPageRaw() {
  const toggleSideContent = useSelector((state: AppState) => state.journal.toggleSideContent);

  return (
    <div className={s('container', { 'toggled-side': toggleSideContent })}>
      <div className={s('side-content')}>
        <ScrollArea className={s('scroll-area')}>
          <div className={s('day-form-wrapper')}>
            <DayForm />
          </div>
          <div className={s('button-container')}>
            <ButtonIcon onClick={() => dispatch(setToggleSideContent(false))}>
              <ChevronRightIcon />
            </ButtonIcon>
          </div>
        </ScrollArea>
      </div>
      <div className={s('main-content')}>
        <DayCalendar />
      </div>
    </div>
  );
}
