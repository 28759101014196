import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Day } from '../../../data/interfaces/models.interface';

import { initJournal } from '../initial-state';
import { TimeUtils } from '../../../utils/time.utils';

const HOUR_CHECK_MOOD = 16;

const journalSlice = createSlice({
  name: 'journal',
  initialState: initJournal,
  reducers: {
    /**
     * Sets selectedMonthDays
     * @secondary sets "selectedDay" to today's date, if undefined
     * @secondary sets "moodIsSet" to to true/false, if undefined
     * @returns
     */
    setSelectedMonthDays: (state, action: PayloadAction<Day[]>) => {
      state.selectedMonthDays = action.payload;
      if (!state.selectedDay.date) {
        const date = TimeUtils.toStartOfDay();
        const todayDay = (state.selectedMonthDays || []).find((day) => day.date === date);
        state.selectedDay = { date, data: todayDay };
        if (state.today.moodIsSet === undefined && TimeUtils.isAfter(HOUR_CHECK_MOOD)) {
          const now = new Date();
          const hours = now.getHours();
          if (todayDay && todayDay.indicators.mood) state.today.moodIsSet = true;
          else state.today.moodIsSet = false;
        }
      }
    },
    selectMonth: (state, action: PayloadAction<{ month: number; year: number }>) => {
      state.selectedMonth = action.payload;
    },
    setDay: (state, action: PayloadAction<{ day: Day; id: string }>) => {
      if (!state.selectedMonthDays) return;

      const dayIndex = state.selectedMonthDays.findIndex((day) => day.id === action.payload.id);
      if (dayIndex !== -1) {
        state.selectedMonthDays[dayIndex] = action.payload.day;
      } else {
        state.selectedMonthDays = [...state.selectedMonthDays, action.payload.day];
        // TODO: Show some notification over not existing. This shouldn't happen. Dev error from frontend.
      }
    },
    todayDay: (state, action: PayloadAction<{ moodIsSet?: boolean }>) => {
      const { moodIsSet } = action.payload;
      if (typeof moodIsSet === 'boolean') state.today.moodIsSet = moodIsSet;
    },
    /**
     * Select a day to view/edit. If "date" undefined, "data" too.
     * @param date iso string.
     * @param date search for day in "selectedMonthDays"
     */
    selectDay: (state, action: PayloadAction<{ date?: string }>) => {
      const { date } = action.payload;
      let newSelectedDay: { date?: string; data?: Day } = {};

      if (date) {
        const day = (state.selectedMonthDays || []).find((day) => day.date === date);
        newSelectedDay = { date, data: day };
      } else {
        newSelectedDay = { date: undefined, data: undefined };
      }

      state.selectedDay = newSelectedDay;
      state.toggleSideContent = true;
    },
    setToggleSideContent: (state, action: PayloadAction<boolean>) => {
      state.toggleSideContent = action.payload;
    }
  }
});

export const { setSelectedMonthDays, setDay, selectMonth, todayDay, selectDay, setToggleSideContent } = journalSlice.actions;
export default journalSlice.reducer;
