import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export abstract class MiscUtils {
  static env(name: string) {
    return process.env[name];
  }

  static run<T>(variable: T | (() => T)): T {
    if (typeof variable === 'function') return (variable as () => T)();
    else return variable;
  }

  static cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
  }

  /**
   * Introduces value in index: arr[index] = value
   * @obs returns same array if index out of bounds
   * @returns copy of new array
   */
  static replaceAtIndex<T>(arr: T[], index: number, value: T): T[] {
    let newArr = [...arr];
    if (index >= 0 && index < newArr.length) newArr[index] = value;
    return newArr;
  }
}
