import { Skeleton } from '../ui/skeleton';

import style from './loading-skeleton.module.scss';
import StyleUtils from '../../utils/style.utils';

const s = StyleUtils.styleMixer(style);

/**
 * DESCRIPTION
 */
export function LoadingSkeleton() {
  return (
    <div className={s('skeleton')}>
      {[1, 2, 3, 4, 5].map((i) => (
        <div key={i} className='flex items-center space-x-4'>
          <Skeleton className='h-[3rem] w-[3rem] rounded-full' />
          <div className='space-y-2'>
            <Skeleton className='h-6 w-[250px]' />
            <Skeleton className='h-6 w-[200px]' />
          </div>
        </div>
      ))}
    </div>
  );
}
