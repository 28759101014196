import { request } from './request';

import { Day } from '../interfaces/models.interface';
import {
  CreateDay,
  CreateDayInput,
  DateSimple,
  GetDaysInCurrentMonth,
  GetDaysInCurrentMonthInput,
  GetDaysInMonth,
  GetDaysInMonthInput,
  GetDaysInMonths,
  GetDaysInMonthsInput,
  UpdateDay,
  UpdateDayByDate,
  UpdateDayByDateInput,
  UpdateDayInput
} from '../../../../../packages/shared/interfaces/shared.interface';

// TODO: Add service folder instead of calling these directly form the components
export class DaysApi {
  public static getDaysInCurrentMonth() {
    return request<GetDaysInCurrentMonthInput, GetDaysInCurrentMonth>('/day/currentMonth', { method: 'GET' });
  }

  public static getDaysInMonth(month: string, year: string) {
    return request<GetDaysInMonthInput, GetDaysInMonth>('/day/month', { method: 'GET', queryParams: { month, year } });
  }

  public static getDaysInMonths(start: string, end: string) {
    return request<GetDaysInMonthsInput, GetDaysInMonths>('/day/month', { method: 'GET', queryParams: { start, end } });
  }

  public static updateDay(id: string, body: Day) {
    return request<UpdateDayInput, UpdateDay>(`/day/${id}`, { method: 'PATCH', body });
  }

  public static updateDayByDate(date: DateSimple, body: Partial<Day>) {
    return request<UpdateDayByDateInput, UpdateDayByDate>(`/day/date/${date}`, { method: 'PATCH', body });
  }

  public static createDay(body: Day) {
    return request<CreateDayInput, CreateDay>(`/day`, { method: 'POST', body });
  }
}
