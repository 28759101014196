import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CaretSortIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import { DaysApi } from '../../../../../data/api/days.api';

import { AppState } from '../../../../../data/redux/store';
import { selectMonth, todayDay } from '../../../../../data/redux/reducers/journal.reducer';

import { cn } from '../../../../../utils/shadcn.utils';
import { TimeUtils } from '../../../../../utils/time.utils';
import { DayFormUtils } from '../../day-form/day-form.utils';
import { useStateRef } from '../../../../../hooks/use-state-ref.hook';

import { Button } from '../../../../../components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '../../../../../components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../components/ui/popover';
import { LevelSelector } from '../../../../../components/level-selector/level-selector.component';
import { ApiService } from '../../../../../data/services/api.service';
import { ButtonIcon } from '../../../../../components/button-icon/button-icon.component';

import style from './lower-menu.module.scss';
import StyleUtils from '../../../../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

const currentMonth = new Date().getMonth() + 1;

// TODO: separate UI from this container
// TODO: add one combobox for years too
// TODO: filteredMonths should change based on selectedMonth.year (if not current year, should show all)

export function LowerMenu() {
  const dispatch = useDispatch();
  const selectedMonth = useSelector((state: AppState) => state.journal.selectedMonth);
  const today = useSelector((state: AppState) => state.journal.today);

  const [open, setOpen] = React.useState(false);

  const filteredMonths = TimeUtils.months.filter((month) => month.value <= currentMonth);

  return (
    <div className={s('container')}>
      {today.moodIsSet === false && (
        <div className={s('mood-selector')}>
          <p>How we feeling today?</p>
          <LevelSelector
            elements={[
              {
                child: <span>{':('}</span>,
                onClick: () => {
                  selectMood(3);
                },
                isActive: false
              },
              {
                child: <span>{':|'}</span>,
                onClick: () => {
                  selectMood(7);
                },
                isActive: false
              },
              {
                child: <span>{':)'}</span>,
                onClick: () => {
                  selectMood(10);
                },
                isActive: false
              }
            ]}
          />
        </div>
      )}

      <div className={s('time-dropdowns')}>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant='outline' role='combobox' aria-expanded={open} className='w-[100px] justify-between'>
              {filteredMonths.find((month) => month.value === selectedMonth.month)?.label}
              <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-[100px] p-0'>
            <Command>
              <CommandList>
                <CommandEmpty>No month found.</CommandEmpty>
                <CommandGroup>
                  {filteredMonths.reverse().map((month) => (
                    <CommandItem
                      key={month.value}
                      value={month.value.toString()}
                      onSelect={(currentValue) => {
                        const selectedValue = parseInt(currentValue, 10);
                        setOpen(false);
                        dispatch(selectMonth({ month: selectedValue, year: selectedMonth.year }));
                      }}
                    >
                      {month.label}
                      <CheckIcon className={cn('ml-auto h-4 w-4', selectedMonth.month === month.value ? 'opacity-100' : 'opacity-0')} />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {/* TODO: To develop year selector */}
        <Popover>
          <PopoverTrigger asChild>
            <Button disabled variant='outline' role='combobox' aria-expanded={open} className='w-[100px] justify-between'>
              {'2024'}
              <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
            </Button>
          </PopoverTrigger>
        </Popover>
      </div>

      <div className={s('button-container')}>
        <ButtonIcon
          onClick={() => {
            const newSelectedMonth = TimeUtils.goToAnotherMonth(selectedMonth, 'next');
            if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
          }}
        >
          <ChevronUpIcon />
        </ButtonIcon>
        <ButtonIcon
          onClick={() => {
            const newSelectedMonth = TimeUtils.goToAnotherMonth(selectedMonth, 'prev');
            if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
          }}
        >
          <ChevronDownIcon />
        </ButtonIcon>
      </div>
    </div>
  );

  async function selectMood(mood: number) {
    const result = await ApiService.updateDay(mood);
    if (!result) return;
    dispatch(todayDay({ moodIsSet: true }));
    // ! TODO: updateDay returns the currentMonth already -> have some useSelector check if dayForm is open on the day changed and update form
    // // await fetchCurrentMonth();
  }
}
