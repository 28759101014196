import ActionButton from '../../../components/action-button/action-button.component';

import StyleUtils from '../../../utils/style.utils';
import style from './screens.module.scss';
const s = StyleUtils.styleMixer(style);

export default function WelcomeScreen(props: { isShowing: boolean; goToLoginScreen: () => void; goToSignup: () => void }) {
  return (
    <div className={s('welcome-screen', props.isShowing ? 'anim-fade-in' : 'anim-fade-out')}>
      <div className={s('titles')}>
        <h1>Active Sloth</h1>
        <h2>Lazily do it!!!</h2>
      </div>
      <ActionButton
        mainBtnLabel='Enter'
        mainBtn={() => props.goToLoginScreen()}
        secondBtnLabel='Not a user?'
        secondBtnLabelBold='Get started'
        secondBtn={() => {
          props.goToSignup();
        }}
      />
    </div>
  );
}
