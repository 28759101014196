import { useState, useEffect } from 'react';

/** Gives value back with delayed render */
export function useDelayedState<T>(value: T, animationDuration: number) {
  const [delayedValue, setDelayedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedValue(value);
    }, animationDuration);

    return () => clearTimeout(timer);
  }, [value, animationDuration]);

  return delayedValue;
}
