import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from '../../../../hooks/use-effect.hook';

import { DaysApi } from '../../../../data/api/days.api';
import { DayParser } from '../../../../data/parsers/day.parser';

import { AppState, dispatch } from '../../../../data/redux/store';
import { setSelectedMonthDays } from '../../../../data/redux/reducers/journal.reducer';

import { TimeUtils } from '../../../../utils/time.utils';
import { JournalUtils } from '../../journal.utlils';
import { useResize } from '../../../../hooks/use-resize.hook';
import { useStateRef } from '../../../../hooks/use-state-ref.hook';
import { useDelayedState } from '../../../../hooks/use-delayed-state.hook';
import { useEffectAfter } from '../../../../hooks/use-effect-after.hook';
import { withInitialization } from '../../../../hoc/with-initialization.hoc';
import withMemo from '../../../../hoc/with-memo.hoc';

import { LowerMenu } from './lower-menu/lower-menu.container';
import { Card, CardHeader, CardTitle } from '../../../../components/ui/card';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import DayGrid from './day-grid/day-grid.component';

import style from './day-calendar.module.scss';
import StyleUtils from '../../../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

export default withInitialization(withMemo(DayCalendarRaw), initJournalPage);

async function initJournalPage() {
  await DaysApi.getDaysInCurrentMonth()
    .then(DayParser.parseDayCurrentMonth)
    .then((currentMonth) => {
      if (!currentMonth) return;
      dispatch(setSelectedMonthDays(currentMonth));
    });
}

// TODO: allow for previous years too
export function DayCalendarRaw() {
  const selectedMonth = useSelector((state: AppState) => state.journal.selectedMonth);
  const selectedMonthDays = useSelector((state: AppState) => state.journal.selectedMonthDays);

  const [[, slideDirection], setSlideDirection] = useStateRef<'top' | 'bottom' | null>(null);
  const [animationClass, setAnimationClass] = useState('');
  const [[, prevMonth], setPrevMonth] = useStateRef<number | null>(null);

  const selectedMonthDaysDelayed = useDelayedState(TimeUtils.getMonthLabel(selectedMonth.month), 200);

  const containerRef = useRef(null);

  const { width } = useResize(containerRef);

  // TODO: lol, put this in a nice hook
  const selectedMonthRef = useRef(selectedMonth);

  useEffect(() => {
    selectedMonthRef.current = selectedMonth;
  }, [selectedMonth]);

  useEffect(
    () => {
      const prevMonthValue = prevMonth();
      if (prevMonthValue !== null) {
        if (selectedMonth.month > prevMonthValue) setSlideDirection('bottom');
        else if (selectedMonth.month < prevMonthValue) setSlideDirection('top');

        // Reset animation class to force re-triggering
        setAnimationClass('');
        setTimeout(() => {
          setAnimationClass(slideDirection() === 'bottom' ? 'slide-in-top' : 'slide-in-bottom');
        }, 100);
      }

      // Update the previous month for next comparison
      setPrevMonth(selectedMonth.month);
    },
    [selectedMonth.month],
    'triggers slide animation'
  );

  useEffectAfter(
    () => {
      // TODO: replace with service call. This effect and the previous could join
      DaysApi.getDaysInMonth(selectedMonth.month.toString(), selectedMonth.year.toString())
        .then(DayParser.parseDayCurrentMonth)
        .then((selectedMonthDays) => {
          if (!selectedMonthDays) return;
          dispatch(setSelectedMonthDays(selectedMonthDays));
        });
    },
    [selectedMonth],
    'fetch days in selected month'
  );

  const filledDays = useMemo(() => JournalUtils.fillDays(selectedMonthDays ?? [], selectedMonth.month, selectedMonth.year), [selectedMonthDays, width]);

  if (!selectedMonthDays) return <></>;

  return (
    <div className={s('container')}>
      <Card className={s('card', animationClass)}>
        <CardHeader style={{ padding: '1rem 1rem 0rem 1rem' }}>
          <CardTitle>{selectedMonthDaysDelayed}</CardTitle>
        </CardHeader>
        <ScrollArea className={s('scroll-area')} viewportRef={containerRef}>
          <div>
            <DayGrid filledDays={filledDays} />
          </div>
        </ScrollArea>
      </Card>

      <div className={s('lower-menu-wrapper')}>
        <LowerMenu />
      </div>
    </div>
  );
}

// TODO: Re-allow swipes: Makes sense to move to same place where up and down arrows are
// const toggleSideContent = useSelectorNR((state: AppState) => state.journal.toggleSideContent);
// useSwipe({
//   onSwipeLeft: () => {
//     if (toggleSideContent()) dispatch(setToggleSideContent(false));
//   },
//   onSwipeRight: () => {
//     if (!toggleSideContent()) dispatch(setToggleSideContent(true));
//   },
//   onSwipeUp: () => {
//     const currentSelectedMonth = selectedMonthRef.current;
//     const newSelectedMonth = TimeUtils.goToAnotherMonth(currentSelectedMonth, 'prev');
//     if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
//   },
//   onSwipeDown: () => {
//     const currentSelectedMonth = selectedMonthRef.current;
//     const newSelectedMonth = TimeUtils.goToAnotherMonth(currentSelectedMonth, 'next');
//     if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
//   },
//   threshold: 200,
//   elementRef: containerRef
// });

// useExtraScroll({
//   onExtraScrollUp: () => {
//     const newSelectedMonth = TimeUtils.goToAnotherMonth(selectedMonth, 'next');
//     if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
//   },
//   onExtraScrollDown: () => {
//     const newSelectedMonth = TimeUtils.goToAnotherMonth(selectedMonth, 'prev');
//     if (newSelectedMonth) dispatch(selectMonth(newSelectedMonth));
//   },
//   extraScrollThreshold: 500,
//   elementRef: containerRef
// });
