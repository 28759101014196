import { useState, useEffect, useRef, forwardRef } from 'react';
import { DevUtils } from '../utils/dev.utils';

/** HOC to setup an async process to only render the component after it is finished
 * @param WrappedComponent component itself.
 * @param initFn async function. When finished, renders component.
 * @param cName (opt) to identify component in console log.
 * @example export default withInitialization(ComponentRaw, initComponent, 'Component');
 */
export function withInitialization<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  initFn: () => Promise<void>
): React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<any>> {
  const ComponentWithInit = forwardRef<any, P>((props, ref) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const renderCount = useRef(0);

    useEffect(() => {
      init();
    }, []);

    // TODO: Work on this, not working very well the counting
    useEffect(() => {
      if (isInitialized && DevUtils.LOGGING_ENABLED) {
        renderCount.current++;
      }
    }, [isInitialized]);

    if (!isInitialized) return null;

    return <WrappedComponent {...props} ref={ref} />;

    async function init() {
      await initFn();
      setIsInitialized(true);
    }
  });

  return ComponentWithInit;
}

export default withInitialization;
