import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { ApiService } from '../../../data/services/api.service';

import ActionButton from '../../../components/action-button/action-button.component';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';

import StyleUtils from '../../../utils/style.utils';
import style from './screens.module.scss';

const s = StyleUtils.styleMixer(style);

const formSchemaLogin = z.object({
  email: z.string().min(2, {
    message: 'Email must be at least 2 characters.'
  }),
  password: z.string()
});

export default function LoginScreen(props: { isShowing: boolean; goToSignup: () => void }) {
  const formLogin = useForm<z.infer<typeof formSchemaLogin>>({
    resolver: zodResolver(formSchemaLogin),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  async function onSubmitLogin(values: z.infer<typeof formSchemaLogin>) {
    ApiService.authenticate('login', { ...values });
  }

  return (
    <div className={s('login-screen', props.isShowing ? 'anim-slide-in' : 'anim-slide-out')}>
      <div className={s('form-card')}>
        <h2>Login</h2>
        <Form {...formLogin}>
          <form onSubmit={formLogin.handleSubmit(onSubmitLogin)} className='space-y-8'>
            <FormField
              control={formLogin.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formLogin.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type='password' placeholder='' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <button type='submit' style={{ display: 'none' }}></button> {/* Hidden submit button (for form actions/listeners) */}
          </form>
        </Form>
      </div>
      <ActionButton
        type='submit'
        mainBtnLabel='Log In'
        mainBtn={() => {
          // Manually trigger the form submission
          formLogin.handleSubmit(onSubmitLogin)();
        }}
        secondBtnLabel="Don't have an account?"
        secondBtnLabelBold='Sign up'
        secondBtn={props.goToSignup}
      />
    </div>
  );
}
