import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isSameDay } from 'date-fns';

import { DisplayDay } from '../../../../containers.interface';

import { AppState, dispatch } from '../../../../../data/redux/store';
import { selectDay } from '../../../../../data/redux/reducers/journal.reducer';
import { TimeUtils } from '../../../../../utils/time.utils';

import { ScrollArea } from '../../../../../components/ui/scroll-area';
import EntryInput from '../../day-form/entry-input/entry-input.component';
import { ClickIcon } from '../../../../../components/click-icon/click-icon.component';

import { ReactComponent as ExpandMore } from 'src/assets/svgs/expand-more-svgrepo.svg';
import { ReactComponent as ExpandLessCircle } from 'src/assets/svgs/expand-less-circle-svgrepo.svg';

import style from './day-cell.module.scss';
import StyleUtils from '../../../../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

const CELL_HEIGHT_PX = 400;

export interface DayCellProps {
  day: DisplayDay;
  updateHeight?: (height: number) => void;
}

export default DayCell;

/**
 * Renders a day cell
 * @obs
 * @param updateHeight runs on component initial renderization and returns component's height
 */
export function DayCell(props: DayCellProps) {
  const { day, updateHeight } = props;

  // TODO: place redux logic in parent component leaving this one clean of state and logic
  const selectedDay = useSelector((state: AppState) => state.journal.selectedDay); // NICE: rerender only if affected by it

  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);

  // const swipelessClick = useSwipelessClick(() => {
  //   dispatch(selectDay({ date: day.date }));
  // });

  useEffect(() => {
    // Delay to ensure DOM attachment
    const timer = setTimeout(() => {
      if (cellRef.current) {
        setShowExpandBtn(cellRef.current.clientHeight > CELL_HEIGHT_PX);
        if (updateHeight) updateHeight(cellRef.current.clientHeight);
      }
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  const date = new Date(day.date);
  const expandCoef = isExpanded ? 2 * CELL_HEIGHT_PX : CELL_HEIGHT_PX;

  return (
    <div ref={cellRef} className={s('container', { empty: !day.data })}>
      <>
        <div
          className={s('cell-header', { selected: isSameDay(date, new Date(selectedDay?.date ?? '')) })}
          onClick={() => {
            dispatch(selectDay({ date: day.date }));
          }}
        >
          {!day.data ? (
            <span className={s('empty-title')}>empty</span>
          ) : day.data?.title ? (
            <strong className={s('title')}>{day.data?.title} </strong>
          ) : (
            <strong></strong>
          )}
          {/* {!day.data?.title && <span>{isSameDay(date, new Date()) ? ' - Today' : ''} </span>} */}
          <span className={s('date')}>{TimeUtils.formattedDate(date)}</span>
        </div>
        {day.data?.entries && (
          <>
            <ScrollArea className={s('scroll-area')}>
              <div className={s('day-card', { 'is-expanded': isExpanded })} style={{ maxHeight: `${expandCoef}px`, overflow: 'hidden' }}>
                {day.data &&
                  day.data?.entries.map((entry, ii) => (
                    <div key={ii} className={s('entry')}>
                      <EntryInput entry={entry} displayOptions={{ displayOnly: true }} />
                    </div>
                  ))}
                {!day.data && <></>}
              </div>
            </ScrollArea>
            <div className={s('click-icon-wrapper')}>
              {showExpandBtn && (
                <ClickIcon
                  tooltip={'expand'}
                  isToggled={isExpanded}
                  toggle={(value) => setIsExpanded(value)}
                  icons={[<ExpandLessCircle />, <ExpandMore />]}
                />
              )}
            </div>
          </>
        )}

        <div className={s('border')} />
      </>
    </div>
  );
}
