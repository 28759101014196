import { MutableRefObject, useRef } from 'react';

/**
 * Quality of life "useRef". Works exactly as a useRef.
 * @obs can be used inside callbacks that lose reference (like event listeners)
 * @usage Use 'getState' inside event listeners to get current value of state ref
 * @param initialState initial state
 * @returns [getState, setState], where "getState" gives the ref value
 */
export function useStateRefNR<T>(initialState: T): [() => T, (stateInput: ((state: T) => T) | T) => void] {
  const stateRef: MutableRefObject<T> = useRef(initialState);

  function setStateRef(stateInput: ((state: T) => T) | T) {
    let newState: T;
    if (typeof stateInput === 'function') newState = (stateInput as (state: T) => T)(stateRef.current);
    else newState = stateInput;
    stateRef.current = newState;
  }

  function getState(): T {
    return stateRef.current;
  }

  return [getState, setStateRef];
}
