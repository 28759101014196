import { endOfMonth } from 'date-fns';
import { Day } from '../../data/interfaces/models.interface';
import { DisplayDay } from '../containers.interface';
import { TimeUtils } from '../../utils/time.utils';

export abstract class JournalUtils {
  /**
   * Returns a list of all days with data, and fills in missing days from the start of the month up to today.
   * @param days already existing days with data
   * @returns array of `{date, data}`. `data` will be undefined for missing days that were filled.
   */
  static fillDays(days: Day[], month: number, year: number): DisplayDay[] {
    const startOfMonth = new Date(year, month - 1, 1, 0, 0, 0, 0);
    const todayDate = new Date(year, month - 1, new Date().getDate(), 0, 0, 0, 0);
    const latestDate = new Date().getMonth() === month - 1 ? todayDate : endOfMonth(new Date(year, month - 1));
    const daysTotal = latestDate.getDate();

    const result: DisplayDay[] = [];

    const existingDaysMap = new Map(
      days.map((day) => {
        const dayDate = new Date(day.date);
        const dateKey = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate()).getTime();
        return [dateKey, day];
      })
    );

    for (let i = 0, dp = new Date(latestDate); dp >= startOfMonth && i < daysTotal; dp.setDate(dp.getDate() - 1), i++) {
      const dayKey = new Date(dp.getFullYear(), dp.getMonth(), dp.getDate()).getTime();
      if (existingDaysMap.has(dayKey)) {
        const dayData = existingDaysMap.get(dayKey);
        if (dayData) {
          const { date, ...data } = dayData;
          result.push({ date: TimeUtils.toStartOfDay(date), data });
        }
      } else {
        result.push({ date: TimeUtils.toStartOfDay(dp) });
      }
    }

    return result;
  }

  /** Sorts days and places them in two columns based on heights
   * @obs Distributes days between two columns, placing them in reverse chronological order, and balances the columns based on their cumulative heights.
   * @param heights in an object form will store the height of each day (day's date is the key)
   */
  static measureColumns(heights: { [key: string]: number }, days: DisplayDay[]) {
    const sortedDays = [...days].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const columnHeights = [0, 0];
    const newColumns: DisplayDay[][] = [[], []];

    sortedDays.forEach((day) => {
      const height = heights[day.date] || 0;

      // Place in the shortest column
      if (columnHeights[0] <= columnHeights[1]) {
        newColumns[0].push(day);
        columnHeights[0] += height;
      } else {
        newColumns[1].push(day);
        columnHeights[1] += height;
      }
    });

    return newColumns;
  }
}
