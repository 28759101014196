import { Pagination, PaginationContent, PaginationItem, PaginationLink } from '../ui/pagination';

import style from './level-selector.module.scss';
import StyleUtils from '../../utils/style.utils';

const s = StyleUtils.styleMixer(style);

export interface LevelSelectorProps {
  elements: {
    onClick: () => void;
    child: React.ReactNode;
    isActive: boolean;
  }[];
}

const defaultProps = {} as Required<LevelSelectorProps>;

/**
 * Multi select for a value (e.g. mood selector)
 */
export function LevelSelector(props: LevelSelectorProps) {
  const { elements } = { ...defaultProps, ...props };

  return (
    <Pagination className={s('container')}>
      <PaginationContent>
        {elements.map(({ child, onClick, isActive }, i) => (
          <PaginationItem key={i}>
            <PaginationLink href='#' isActive={isActive} onClick={onClick}>
              {child}
            </PaginationLink>
          </PaginationItem>
        ))}
      </PaginationContent>
    </Pagination>
  );
}
