import { IJournalState, ISessionState, IUserState } from '../interfaces/redux.interface';

export const initJournal: IJournalState = {
  selectedMonthDays: undefined,
  status: 'idle',
  today: {},
  selectedDay: { date: undefined, data: undefined },
  selectedMonth: {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  },
  toggleSideContent: false
};

export const initSession: ISessionState = {
  triggers: {},
  loaders: {}
};

export const initialUserState: IUserState = {
  userData: null,
  isLoggedIn: false,
  isLogging: false,
  status: 'idle',
  error: null
};
