import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useToast } from '../../../../components/ui/use-toast';

import { Day, Entry } from '../../../../data/interfaces/models.interface';
import { DayDTO, EntryDTO } from '../../../../../../../packages/shared/interfaces/shared.interface';

import { AppState, dispatch } from '../../../../data/redux/store';
import { setLoading } from '../../../../data/redux/reducers/session.reducer';
import { setDay, setSelectedMonthDays } from '../../../../data/redux/reducers/journal.reducer';
import { TimeUtils } from '../../../../utils/time.utils';
import { DayFormUtils } from './day-form.utils';
import { DaysApi } from '../../../../data/api/days.api';
import { isErrorResponse } from '../../../../data/api/request';

import { useStateRef } from '../../../../hooks/use-state-ref.hook';
import { useDebounce } from '../../../../hooks/use-debounce.hook';
import { useSelectorNR } from '../../../../hooks/use-selector-no-render.hook';

import { Card, CardHeader, CardTitle } from '../../../../components/ui/card';
import { Input } from '../../../../components/ui/input';
import EntryInput from './entry-input/entry-input.component';
import LoadingIcon from '../../../../components/loading-icon/loading-icon.component';
import { LevelSelector } from '../../../../components/level-selector/level-selector.component';
import { LoadingSkeleton } from '../../../../components/loading-skeleton/loading-skeleton.component';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../../components/ui/tooltip';

import { ReactComponent as CircleCheck } from '../../../../assets/svgs/circle-check-svgrepo.svg';
import { ReactComponent as Circle } from '../../../../assets/svgs/circle-svgrepo.svg';

import style from './day-form.module.scss';
import StyleUtils from '../../../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

const AUTO_SAVE_DELAY_MS = 3000;

const emptyDay: Day = {
  id: '',
  date: new Date().toString(),
  title: '',
  indicators: {},
  entries: [{ text: '', tag: undefined }]
};

export default function DayForm() {
  const autoSaveTimeout = useDebounce(AUTO_SAVE_DELAY_MS, 'run-last');

  const selectedMonthDays = useSelector((state: AppState) => state.journal.selectedMonthDays);
  const selectedMonth = useSelectorNR((state: AppState) => state.journal.selectedMonth);
  const selectedDay = useSelector((state: AppState) => state.journal.selectedDay);
  const loadingSaveDay = useSelector((state: AppState) => state.session.loaders['save-day'] === 'loading');

  const [[dayFormState, dayForm], setDayForm] = useStateRef<Day>(emptyDay);
  const [[, saved], setSaved] = useStateRef(true);

  useEffect(() => selectForm(), [selectedDay]);
  useEffect(() => addEmptyEntry(dayForm().entries), [dayFormState]);

  const { toast } = useToast();

  if (!selectedMonthDays || !selectedDay.date) return <LoadingSkeleton />;

  return (
    <div className={s('container')}>
      <Card className={s('card')}>
        <CardHeader>
          {selectedDay.date && <CardTitle>{TimeUtils.formatDate(selectedDay.date)}</CardTitle>}
          {/* <CardDescription>{TimeUtils.relativeTimeDifference(selectedDay.date, new Date().toISOString())}</CardDescription> */}
          <Tooltip>
            <TooltipTrigger asChild>
              <div className={s('save-icon', { fill: !saved() })} onClick={() => saveChanges()}>
                {loadingSaveDay ? <LoadingIcon /> : saved() ? <CircleCheck /> : <Circle className={s('save-btn')} />}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{loadingSaveDay ? 'loading...' : saved() ? 'saved' : 'unsaved'}</p>
            </TooltipContent>
          </Tooltip>
        </CardHeader>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='grid w-full items-center gap-4'>
            <div className='flex flex-col space-y-1.5'>
              <Input id='title' placeholder='Title' value={dayForm().title} onChange={(e) => setDayForm({ ...dayForm(), title: e.target.value })} onBlur={() => ifIdleSave()} />
            </div>

            <div className={s('entries')}>
              {dayForm()?.entries.map((entry: any, index: number) => {
                return <EntryInput key={index} entry={entry} update={(entry: Entry) => updateEntry(entry, index)} delete={() => deleteEntry(index)} onBlur={() => ifIdleSave()} />;
              })}
            </div>
          </div>

          <div className={s('mood-selector')}>
            <LevelSelector
              elements={[
                {
                  child: <span>{':('}</span>,
                  onClick: () => {
                    selectMood(3);
                  },
                  isActive: DayFormUtils.isValueBetween(dayForm().indicators.mood, 1, 3)
                },
                {
                  child: <span>{':|'}</span>,
                  onClick: () => {
                    selectMood(7);
                  },
                  isActive: DayFormUtils.isValueBetween(dayForm().indicators.mood, 4, 7)
                },
                {
                  child: <span>{':)'}</span>,
                  onClick: () => {
                    selectMood(10);
                  },
                  isActive: DayFormUtils.isValueBetween(dayForm().indicators.mood, 8, 10)
                }
              ]}
            />
          </div>
        </form>
      </Card>
    </div>
  );

  function selectMood(mood: number) {
    setDayForm((prevDayForm: any) => ({ ...prevDayForm, indicators: { ...prevDayForm.indicators, mood } }));
    ifIdleSave();
  }

  function selectForm() {
    if (!selectedMonthDays) return;

    // On first time (no selected day, defaults to today)
    if (!selectedDay.date) {
      setDayForm({ ...(selectedMonthDays[0] || {}), date: selectedMonthDays[0].date });
      return;
    }

    // Selected new day
    if (!selectedDay.data) {
      setDayForm({ ...emptyDay, date: selectedDay.date });
      return;
    }

    // Update existing day
    if (selectedDay.data) {
      setDayForm({ ...selectedDay.data, date: selectedDay.date });
      return;
    }
  }

  /** Starts auto save counter: idle more than AUTO_SAVE_DELAY_MS will save form changes*/
  function ifIdleSave() {
    if (saved()) setSaved(false);
    if (!loadingSaveDay) dispatch(setLoading({ loadingType: 'save-day', loadingState: 'loading' }));
    autoSaveTimeout(() => {
      if (saved() || loadingSaveDay) return;
      saveChanges();
    });
  }

  /** Save changes to backend */
  // TODO: this is a monster...
  async function saveChanges() {
    if (!loadingSaveDay) dispatch(setLoading({ loadingType: 'save-day', loadingState: 'loading' }));

    const isUpdate = !!selectedDay.data;

    const trimmedForm = {
      ...dayForm(),
      entries: dayForm().entries.filter((entry: any) => entry.text !== '')
    };

    let newUpdatedDay: DayDTO | null;

    const res = isUpdate ? await DaysApi.updateDay(trimmedForm.id, trimmedForm) : await DaysApi.createDay(trimmedForm);
    newUpdatedDay = !isErrorResponse(res) ? { ...res?.data.dayDTO } : null;
    if (!newUpdatedDay) {
      toast({
        title: 'Something went wrong.',
        description: 'Please try saving again.',
        variant: 'destructive'
      });
      return;
    }

    dispatch(setDay({ id: newUpdatedDay.id, day: newUpdatedDay }));

    const isPastMonth = selectedMonth().year !== new Date().getFullYear() || selectedMonth().month - 1 !== new Date().getMonth();

    if (isPastMonth) {
      DaysApi.getDaysInMonth(selectedMonth().month.toString(), selectedMonth().year.toString()).then((selectedMonthDays) => {
        if (!isErrorResponse(selectedMonthDays)) dispatch(setSelectedMonthDays(selectedMonthDays.data));
        else {
          toast({
            title: 'Calendar view was not updated.',
            description: 'Please refresh your page.',
            variant: 'destructive'
          });
        }
      });
    } else {
      DaysApi.getDaysInCurrentMonth().then((currentMonthRes) => {
        if (!isErrorResponse(currentMonthRes)) dispatch(setSelectedMonthDays(currentMonthRes.data));
        else {
          toast({
            title: 'Calendar view was not updated.',
            description: 'Please refresh your page.',
            variant: 'destructive'
          });
        }
      });
    }

    dispatch(setLoading({ loadingType: 'save-day', loadingState: 'idle' }));
    setSaved(true);
  }

  function updateEntry(entry: Entry, index: number) {
    let newEntries = [...dayForm().entries];
    newEntries[index] = entry;
    setDayForm((prevDayForm) => ({ ...prevDayForm, entries: newEntries }));

    if (saved()) setSaved(false);
  }

  function deleteEntry(index: number) {
    let newEntries = [...dayForm().entries];
    newEntries.splice(index, 1);
    setDayForm((prevDayForm) => ({ ...prevDayForm, entries: newEntries }));

    if (saved()) setSaved(false);
    ifIdleSave();
  }

  function addEmptyEntry(entries: EntryDTO[]) {
    const lastEntryText = entries[entries.length - 1]?.text || '';
    const secondLastEntryText = entries[entries.length - 2]?.text || '';

    // Ensure always an empty entry input
    const needNewEntryInput = entries.length === 0 || (lastEntryText && lastEntryText.trim() !== '');
    if (needNewEntryInput) {
      let newEntries = [...entries];
      newEntries.push({ text: '', tag: undefined });
      setDayForm((prevDayForm: any) => ({ ...prevDayForm, entries: newEntries }));
    }

    // If both the last and second-to-last entries are empty, remove the last one
    const hasTooManyEntryInputs = entries.length > 1 && lastEntryText.trim() === '' && secondLastEntryText.trim() === '';
    if (hasTooManyEntryInputs) {
      let newEntries = [...entries];
      newEntries.pop(); // Remove the last entry
      setDayForm((prevDayForm: any) => ({ ...prevDayForm, entries: newEntries }));
    }
  }
}
