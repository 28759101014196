import { useState, useEffect, useRef, ComponentType } from 'react';

/**
 * This HOC performs asynchronous operations on props but prevents rerendering until the async operation is complete.
 * The component is only updated once the async logic has finished, without showing loading states.
 * @param WrappedComponent The component you want to wrap.
 * @param asyncProcessProps A function that returns a promise to process props asynchronously.
 */
function withAsyncProps(WrappedComponent: ComponentType<any>, asyncProcessProps: (props: any) => Promise<any>) {
  return function AsyncComponent(props: any) {
    const [processedProps, setProcessedProps] = useState<any>(props);
    const isProcessing = useRef<Symbol | null>(null);

    useEffect(() => {
      let isMounted = true;
      const currentProcessId = Symbol();
      isProcessing.current = currentProcessId;

      async function processProps() {
        try {
          const result = await asyncProcessProps(props);
          if (isMounted && isProcessing.current === currentProcessId) {
            setProcessedProps(result);
          }
        } catch (error) {
          console.error('Error processing props:', error);
        }
      }

      processProps();

      return () => {
        isMounted = false;
      };
    }, [props]);

    return <WrappedComponent {...processedProps} />;
  };
}

export default withAsyncProps;
