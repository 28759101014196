import { Day } from '../data/interfaces/models.interface';

export abstract class TimeUtils {
  static formattedDate(date: Date) {
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short' // e.g: 10 May
    });
  }

  static sortDays(days: Day[]): Day[] {
    return [...days].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  /**
   * Transforms any date to DD-MM-YYYY or DD/MM/YYYY
   * @param input Date instance OR date string in ISO form (defaults to now date)
   * @param format '-' or '/'
   * @returns date printed as DD-MM-YYYY or DD/MM/YYYY
   */
  static dateDD_MM_YYYY(config: { input?: string | Date; format?: '-' | '/' } = {}) {
    const { input = new Date().toLocaleString(), format = '-' } = config;
    const date = typeof input === 'string' ? new Date(input) : input;
    if (isNaN(date.getTime())) throw new Error('Invalid date');

    if (format === '-') {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`; // Return as DD-MM-YYYY
    }

    return date.toLocaleDateString('en-GB'); // Formats as DD/MM/YYYY
  }

  /** Gives Date string but resets hour/min/sec to 0 */
  static toStartOfDay(date: Date | string = new Date()): string {
    const inputDate = new Date(date);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}T00:00:00.000Z`;
  }

  /** Checks if local date is over a certain hour (pm format) */
  static isAfter(hour: number = 17): boolean {
    const now = new Date();
    const hours = now.getHours();
    return hours >= hour;
  }

  // Function to format date as "9 Feb 2024 - Fri"
  static formatDate(input: string | Date): string {
    let date: Date;

    const ddMmYyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (typeof input === 'string' && ddMmYyyyRegex.test(input)) {
      const [day, month, year] = input.split('/').map(Number);
      date = new Date(year, month - 1, day); // Month is 0-indexed
    } else {
      date = typeof input === 'string' ? new Date(input) : input;
    }

    if (isNaN(date.getTime())) throw new Error('Invalid date');

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short'
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate.replace(/,/, ' -'); // Replace the comma with " -"
  }

  // Function to calculate relative time difference
  static relativeTimeDifference(providedDateIso: string, currentDateIso: string): string {
    const providedDate = new Date(providedDateIso);
    const currentDate = new Date(currentDateIso);
    const msPerDay = 24 * 60 * 60 * 1000;
    const daysDiff = Math.round((currentDate.getTime() - providedDate.getTime()) / msPerDay);

    if (daysDiff < 7) {
      return `${daysDiff} days ago`;
    } else if (daysDiff < 30) {
      return `${Math.floor(daysDiff / 7)} week(s) ago`;
    } else if (daysDiff < 365) {
      return `${Math.floor(daysDiff / 30)} month(s) ago`;
    } else {
      return `${Math.floor(daysDiff / 365)} year(s) ago`;
    }
  }

  /** Get prev/next month from selected. Returns undefined if trying a month in the future */
  static goToAnotherMonth(selected: { month: number; year: number }, to: 'prev' | 'next'): { month: number; year: number } | undefined {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let { month, year } = selected;

    if (to === 'next') {
      if (year > currentYear || (year === currentYear && month >= currentMonth)) return undefined;

      if (month === 12) {
        month = 1;
        year += 1;
      } else {
        month += 1;
      }
    } else if (to === 'prev') {
      if (month === 1) {
        month = 12;
        year -= 1;
      } else {
        month -= 1;
      }
    }

    return { month, year };
  }

  static getMonthLabel(month: number): string {
    if (month < 1 || month > 12) return '-';
    const label = TimeUtils.months.find((m) => m.value === month)?.label;
    return label ?? '-';
  }

  static months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];
}
