import { useEffect, useRef } from 'react';
import { DevUtils } from '../utils/dev.utils';

/** useEffect, but doesn't run on first pass */
export function useEffectAfter(fn: () => void, deps: any[], identifier?: string) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (identifier && DevUtils.LOGGING_ENABLED) console.info('useEffect: ', identifier);
      fn();
    } else didMount.current = true;
  }, deps);
}
