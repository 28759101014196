import { MiscUtils } from '../../utils/misc.utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../components/ui/tooltip';

import style from './click-icon.module.scss';
import StyleUtils from '../../utils/style.utils';
const s = StyleUtils.styleMixer(style);

export interface ClickIconProps {
  tooltip: string | (() => string);
  isToggled: boolean;
  toggle: (value: boolean) => void;
  icons: [JSX.Element, JSX.Element];
}

export function ClickIcon(props: ClickIconProps) {
  const { tooltip, isToggled, toggle, icons } = props;
  return (
    <div className={s('icon-container')}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div onClick={() => toggle(!isToggled)} className={s('click-icon', { fill: !isToggled })}>
            {isToggled ? icons[0] : icons[1]}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{MiscUtils.run(tooltip)}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}
