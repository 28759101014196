import { ChangeEvent, useLayoutEffect, useRef } from 'react';
import { DayFormUtils } from '../day-form.utils';

import { Entry } from '../../../../../data/interfaces/models.interface';
import { Tag } from '../../../../../../../../packages/shared/interfaces/shared.interface';

import { Tooltip, TooltipContent, TooltipTrigger } from '../../../../../components/ui/tooltip';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../components/ui/popover';
import { Textarea } from '../../../../../components/ui/textarea';

import { ReactComponent as BanIcon } from '../../../../../assets/svgs/ban-fa.svg';
import { Cross1Icon } from '@radix-ui/react-icons';

import style from './entry-input.module.scss';
import StyleUtils from '../../../../../utils/style.utils';
import { ButtonIcon } from '../../../../../components/button-icon/button-icon.component';
const s = StyleUtils.styleMixer(style);

export interface EntryInputProps {
  entry: Entry;
  update?: (entry: Entry) => void;
  delete?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  displayOptions?: {
    /** Input is not editable & minor display changes occur */
    displayOnly?: boolean;
  };
}

/**
 * Input for a Day Entry, with tag menu selection
 */
export default function EntryInput(props: EntryInputProps) {
  const { entry, update, onChange, onBlur, displayOptions } = props;
  const { displayOnly } = displayOptions ?? {};
  const { Icon: SelectedTagIcon, color: tagColor, displayName } = DayFormUtils.getTagDetails(entry.tag);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (textAreaRef.current) {
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [entry.text]);

  return (
    <div className={s('container')} style={{ backgroundColor: tagColor }}>
      <Popover>
        <PopoverTrigger asChild>
          {!(displayOnly && !SelectedTagIcon) && (
            <div className={s('tag-menu', { 'read-only': !!displayOnly })} style={{ backgroundColor: tagColor }}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>{SelectedTagIcon}</div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{displayName}</p>
                </TooltipContent>
              </Tooltip>
              <PopoverContent side='right'>
                <div className={s('options-tag')}>
                  {DayFormUtils.tags.map((tag, index) => {
                    const { Icon: TagIcon, color: tagColor, displayName } = DayFormUtils.getTagDetails(tag);
                    return (
                      <Tooltip key={index}>
                        <TooltipTrigger asChild>
                          <span
                            style={{
                              backgroundColor: StyleUtils.changeHexColor(tagColor, 0.5, 'more-color')
                            }}
                            onClick={() => handleTagChange(tag)}
                          >
                            {TagIcon}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{displayName}</p>
                        </TooltipContent>
                      </Tooltip>
                    );
                  })}
                  <span onClick={() => handleTagChange(undefined)}>
                    <BanIcon />
                  </span>
                </div>
              </PopoverContent>
            </div>
          )}
        </PopoverTrigger>
      </Popover>

      <Textarea
        ref={textAreaRef}
        value={entry.text}
        onFocus={(event) => !displayOnly && handleTextFocus(event)}
        onChange={(event) => !displayOnly && handleTextChange(event)}
        onBlur={(event) => !displayOnly && handleTextBlur(event)}
        placeholder={'Your entry...'}
        style={{
          fontSize: `${DayFormUtils.LINE_HEIGHT_PX - 4}px`,
          lineHeight: `${DayFormUtils.LINE_HEIGHT_PX}px`,
          backgroundColor: tagColor,
          resize: 'none',
          height: displayOnly ? '36px' : '36px'
        }}
        readOnly={displayOnly}
      />
      {!displayOnly && (
        <div className={s('btn-delete')}>
          <ButtonIcon
            onClick={() => {
              props.delete && props.delete();
            }}
          >
            <Cross1Icon color='white' style={{ backgroundColor: '#cc2244', padding: '5px' }} />
          </ButtonIcon>
        </div>
      )}
    </div>
  );

  function handleTagChange(tag: Tag | undefined) {
    if (update) update({ ...entry, tag });
    if (update && onBlur) onBlur();
  }

  function handleTextFocus({ target }: React.FocusEvent<HTMLTextAreaElement>) {
    target.style.height = `${1}px`;
    const updatedText = target.value.replace(/[\s\n\t]+$/g, '');
    updateTargetEntry(target, updatedText, target.scrollHeight);
  }

  function handleTextBlur({ target }: React.FocusEvent<HTMLTextAreaElement>) {
    target.style.height = `${1}px`;
    let updatedHeight = target.scrollHeight;
    let updatedText = target.value;

    // Adjust height to last line
    if (updatedText.endsWith('\n')) {
      const numberNewLines = (updatedText.match(/\n+$/) ?? [])[0]?.length ?? 0;
      updatedHeight = updatedHeight - DayFormUtils.LINE_HEIGHT_PX * numberNewLines + 1;
    }

    updatedText = updatedText.replace(/[\s\n\t]+$/g, '');
    updateTargetEntry(target, updatedText, updatedHeight);
    if (onBlur) onBlur();
  }

  function handleTextChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    // Press 'Double Enter'
    if (target.value.endsWith('\n\n') && target.selectionStart === target.value.length) {
      target.blur(); // Triggers "handleTextBlur"
      return;
    }
    if (onChange) onChange();
    updateTargetEntry(target, target.value, target.scrollHeight);
  }

  function updateTargetEntry(target: EventTarget & HTMLTextAreaElement, text: string, heightPx: number) {
    if (update) update({ ...entry, text });
    target.style.height = `${heightPx}px`;
  }
}
