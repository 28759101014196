import { isErrorResponse } from '../api/request';
import { UsersApi } from '../api/users.api';
import Cookies from 'js-cookie';
import { dispatch } from '../redux/store';
import { login } from '../redux/reducers/user.reducer';
import { DaysApi } from '../api/days.api';
import { DayParser } from '../parsers/day.parser';
import { setSelectedMonthDays } from '../redux/reducers/journal.reducer';
import { TimeUtils } from '../../utils/time.utils';

interface SignupParams {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

interface LoginParams {
  email: string;
  password: string;
}

export abstract class ApiService {
  /** Send a signup or login request. On response, set cookies, local variables & redux state with user data */
  static async authenticate(type: 'login' | 'signup', values: SignupParams | LoginParams) {
    const res = await (type === 'signup' ? UsersApi.userSignup(values as SignupParams) : UsersApi.userLogin(values as LoginParams));
    if (isErrorResponse(res)) return null;

    if (res.token) Cookies.set('jwt', res.token, { expires: 7, path: '/' });
    if (res.uk2) Cookies.set('uk2', res.uk2, { expires: 7, path: '/' });
    dispatch(login({ user: res.data }));
    localStorage.setItem('hasVisited', 'true');
  }

  //TODO: change this to be multipurpose
  /** */
  static async updateDay(mood: number) {
    const res = await DaysApi.updateDayByDate(TimeUtils.dateDD_MM_YYYY({ format: '-' }), { indicators: { mood } });
    if (isErrorResponse(res)) return null;
    return res;
  }

  /** Call "getDaysInCurrentMonth" and store it with "setSelectedMonthDays" */
  static fetchCurrentMonth() {
    return DaysApi.getDaysInCurrentMonth()
      .then(DayParser.parseDayCurrentMonth)
      .then((currentMonth) => {
        if (!currentMonth) return;
        dispatch(setSelectedMonthDays(currentMonth));
        return currentMonth;
      });
  }
}
